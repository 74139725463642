export const WORDS = [
  'drive',
 'teach',
'tutor', 
'genre', 
'ready',
'desks',
'globe',
'chalk',
'board',
'major',
'reads',
'halls',
'sport',
'grade',
'teens',
'psych',
'staff',
  ]
